/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import _ from "lodash";
import Modal from "./Modal";
import styles from "./ClientResponse.module.scss";

import {
  fetchSummary,
  ServiceResponse,
  createUser,
  xplanAccess,
  xplanTransactions,
} from "../lib/services";
import {
  getFullName1,
  getFullName2,
  getDOB1,
  getDOB2,
  getMphone1,
  getMphone2,
} from "../lib/utils";
import { ClientSummarySchema } from "../schemas/clientSummaryResponseSchema.json";
import { XplanAccessSchema } from "../schemas/xplanAccessSchema.json";
import { XplanTransactionsSchema } from "../schemas/xplanTransactionsSchema.json";

interface DetailsModalProps {
  showModal: boolean;
  onClose: () => void;
  uid: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
      fontSize: "20",
    },
    root2: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonSuccess: {
      backgroundColor: "var(--success-color)",
      "&:hover": {
        backgroundColor: "var(--success-color)",
      },
    },
    fabProgress: {
      color: "var(--success-color)",
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: "var(--success-color)",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

const DetailsModal: React.FC<DetailsModalProps> = (props) => {
  const classes = useStyles();

  const [summaryRes, setSummaryRes] = useState<
    ServiceResponse<ClientSummarySchema>
  >({ kind: "LOADING", value: null });

  const summary: ClientSummarySchema = summaryRes.value;

  const [xplan, setXplan] = useState<ServiceResponse<XplanAccessSchema>>({
    kind: "LOADING",
    value: { client_id: "" },
  });

  const xp: XplanAccessSchema = xplan.value;

  const [xplanTrans, setXplanTrans] = useState<
    ServiceResponse<XplanTransactionsSchema>
  >({
    kind: "LOADING",
    value: { uid: "" },
  });

  const xpt: XplanTransactionsSchema = xplanTrans.value;

  const initiateData = (): void => {
    Promise.all([
      fetchSummary(props.uid),
      xplanTransactions(props.uid),
      xplanAccess(),
    ])
      .then(([client, xplanTrans, xplan]) => {
        setSummaryRes(client);
        setXplan(xplan);
        setXplanTrans(xplanTrans);
      })
      .catch((error) => console.log("Error in promises :", { error }));
  };

  const form: HTMLFormElement | null = document.querySelector("#myform");

  useEffect(() => {
    initiateData();
    console.log("xplanID :", xp.xplanID);
  }, [props.uid, xp.client_id, form]);

  const [transferId, setTransferId] = useState<string>("");
  const [primeId, setPrimeId] = useState<string>("");
  const [primeEntityName, setPrimeEntityName] = useState<string>("");
  const [partnerEntityId, setPartnerEntityId] = useState<string>("");
  const [partnerId, setPartnerId] = useState<string>("");
  const [partnerEntityName, setPartnerEntityName] = useState<string>("");
  const [transferButton, setTransferButton] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState<string>("");

  const buttonClassname = clsx({
    [classes.buttonSuccess]: status === "success",
  });

  const getMinutesRemaining = (dateTime: any) => {
    const currentDateTime = new Date();
    const regDateTime = new Date(dateTime);
    const currentDateTimeMilli = currentDateTime.getTime();
    const regDateTimeMilli = regDateTime.getTime() + 3_600_000;
    const difference = regDateTimeMilli - currentDateTimeMilli; // This will give difference in milliseconds
    const resultInMinutes = Math.round(difference / 60_000);
    let finalResult = "";

    if (resultInMinutes < 0) {
      finalResult =
        "Note your token has expired, please click AUTHORISE button to get a new token";
    } else {
      finalResult =
        resultInMinutes > 1
          ? `Your token will expire in ${resultInMinutes} minutes`
          : `Your token will expire in ${resultInMinutes} minute`;
    }

    return finalResult;
  };

  useEffect(() => {
    if (transferButton === true) {
      console.log("transferclick called");
      setLoading(true);

      createUser(props.uid)
        .then((mess: any) => {
          if (!isNaN(mess)) {
            setTransferId("");
            setStatus(mess);
          } else {
            setTransferId(mess);
            setStatus("success");
            const edaiReturnObject = JSON.parse(mess);
            const edaiReturnPrime = JSON.parse(edaiReturnObject.Prime);

            const edaiReturnPartner = !_.isNil(edaiReturnObject.Partner)
              ? JSON.parse(edaiReturnObject.Partner)
              : null;

            setPrimeId(edaiReturnPrime.id);
            setPrimeEntityName(edaiReturnPrime.entity_name);

            if (!_.isNil(edaiReturnPartner)) {
              setPartnerEntityId(edaiReturnPartner.partner_entity_id);
              setPartnerId(edaiReturnPartner.id);
              setPartnerEntityName(edaiReturnPartner.entity_name);
            } else {
              setPartnerEntityId("");
              setPartnerId("");
              setPartnerEntityName("");
            }

            initiateData();
          }

          setLoading(false);
          setTransferButton(false);
        })
        .catch((error) => {
          console.log("useEffect catch :", error);
          setStatus("failed");
          setLoading(false);
          setTransferButton(false);
        });
    }
  }, [transferButton]);

  if (summaryRes.kind === "ERROR") {
    return <span>Error</span>;
  }

  const urlredirect:
    | string
    | undefined = `${process.env.REACT_APP_API_URL}xplan/callback/?state=${xp.xplanID}`;

  const xplanurl: string | undefined = process.env.REACT_APP_XPLAN_URL;
  let severity: any;
  let alertTitle = "";
  let alertMessage = "";

  switch (status) {
    case "":
      severity = "info";
      alertTitle = "XPlan Integration";

      alertMessage = `If you wish to transfer this user to XPlan, please click the UPLOAD XPLAN button, this will create a new prospect record. ${getMinutesRemaining(
        xp.tokenDate
      )}. ${
        !_.isNil(xpt.transaction)
          ? `The latest transfer of records to XPlan for this user was on ${moment(
              xpt.transaction?.slice(-1)[0].dateSubmitted
            ).format("LLL")}.`
          : ``
      }`;

      break;

    case "success":
      severity = "success";
      alertTitle = "Records created in Xplan";
      alertMessage = `Sucessfully transferred data into a new Xplan prospect.`;
      break;

    case "failed":
      severity = "error";
      alertTitle = "Failed to create user";
      alertMessage = `Unknown error occured. Please check XPLAN response to fix the problem.`;
      break;

    case "401":
      severity = "error";
      alertTitle = "Failed to create user";
      alertMessage = `We were not able to create a new user in XPlan. Your token may need to be refreshed, please click the AUTHORISE button.`;
      break;

    case "424":
      severity = "error";
      alertTitle = "Failed to create user";
      alertMessage = `We detected error 424 and this is caused by a function being called on a reference you've either misspelled, or have not declared.`;
      break;

    case "500":
      severity = "error";
      alertTitle = "Failed to create user";
      alertMessage = `We detected error 500, this indicates that the server encountered an unexpected condition that prevented it from fulfilling the request.`;
      break;

    default:
      severity = "error";
      alertTitle = "Failed to create user";
      alertMessage = `Unknown error occured. Please check XPLAN response to fix the problem.`;
      console.log("transferIdBefore :", transferId);

      if (transferId === '"1"') {
        console.log("hit!!!");
      }

      console.log("transferIdAfter :", transferId);
      console.log("typeof :", typeof transferId);
      break;
  }

  const renderInsertedAccount = (status: string): JSX.Element | undefined => {
    if (status === "success") {
      return (
        <>
          <br />
          <br />
          <span style={{ fontSize: "13px", fontWeight: "bold" }}>Prime:</span>
          <br />
          <span style={{ fontSize: "12px" }}>
            id: <i style={{ fontWeight: "bold" }}>{primeId}</i>
          </span>
          <br />
          <span style={{ fontSize: "12px" }}>
            entity_name: <i style={{ fontWeight: "bold" }}>{primeEntityName}</i>
          </span>
          <br />
          <br />
          <span style={{ fontSize: "13px", fontWeight: "bold" }}>Partner:</span>
          <br />
          <span style={{ fontSize: "12px" }}>
            partner_entity_id:{" "}
            <i style={{ fontWeight: "bold" }}>{partnerEntityId}</i>
          </span>
          <br />
          <span style={{ fontSize: "12px" }}>
            id: <i style={{ fontWeight: "bold" }}>{partnerId}</i>
          </span>
          <br />
          <span style={{ fontSize: "12px" }}>
            entity_name:{" "}
            <i style={{ fontWeight: "bold" }}>{partnerEntityName}</i>
          </span>
        </>
      );
    }
  };

  return (
    <Modal showModal={props.showModal} onClose={props.onClose}>
      <form id="myform" action={xplanurl} method="GET">
        <input type="hidden" name="client_id" value={xp.client_id ?? ""} />
        <input type="hidden" name="response_type" value="code" />
        <input type="hidden" name="redirect_uri" value={urlredirect} />
      </form>

      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "15px" }}
          >
            <Alert severity={severity}>
              <AlertTitle style={{ fontSize: "15px", fontWeight: "bold" }}>
                {alertTitle}
              </AlertTitle>
              <span style={{ fontSize: "12px" }}>{alertMessage}</span>
              {renderInsertedAccount(status)}
            </Alert>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h5 style={{ color: "var(--primary-color)" }}>
              Primary Client (Main)
            </h5>
            <div style={{ marginBottom: "3px" }}>
              <b>Prime ID (XPlan)</b>: <br />
              {!_.isNil(xpt.transaction) ? (
                xpt.transaction?.slice(-1)[0].primeId
              ) : (
                <span
                  style={{ color: "var(--grey-color-3)", fontStyle: "italic" }}
                >
                  No records exist.
                </span>
              )}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Name</b>: <br />
              {getFullName1(summary)}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Date of Birth</b>: <br />
              {getDOB1(summary)}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Mobile Phone</b>: <br />
              {getMphone1(summary)}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h5 style={{ color: "var(--primary-color)" }}>
              Secondary Client (Partner)
            </h5>
            <div style={{ marginBottom: "3px" }}>
              <b>Partner ID (XPlan)</b>: <br />
              {!_.isNil(xpt.transaction) ? (
                xpt.transaction?.slice(-1)[0].partnerId
              ) : (
                <span
                  style={{ color: "var(--grey-color-3)", fontStyle: "italic" }}
                >
                  No records exist.
                </span>
              )}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Name</b>: <br />
              {getFullName2(summary)}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Date of Birth</b>: <br />
              {getDOB2(summary)}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Mobile Phone</b>: <br />
              {getMphone2(summary)}
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginBottom: "8px" }}
          >
            {/* TODO change flow so that only an indicator of a valid token is present   */}
            {((): JSX.Element | undefined => {
              if (xp.tokenStatus == "validKey") {
                return (
                  <div className={classes.root2}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="upload"
                        color="primary"
                        className={buttonClassname}
                        onClick={(): void => {
                          setTransferButton(true);
                        }}
                      >
                        {status === "success" ? (
                          <CheckIcon />
                        ) : (
                          <PublishRoundedIcon />
                        )}
                      </Fab>
                      {loading && (
                        <CircularProgress
                          size={68}
                          className={classes.fabProgress}
                        />
                      )}
                    </div>
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={loading}
                        onClick={(): void => {
                          setTransferButton(true);
                        }}
                      >
                        Upload Xplan
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                );
              }
            })()}

            <div>
              {xp.tokenStatus !== "validKey1" &&
              xp.client_id !== "" &&
              form !== null ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.primaryButton2}
                  onClick={(): void => {
                    form.submit();
                  }}
                >
                  Authorise
                </Button>
              ) : null}

              <Button
                variant="contained"
                color="primary"
                className={styles.primaryButton}
                onClick={(): void => {
                  setStatus("");
                  setLoading(false);
                  setTransferButton(false);
                  setTransferId("");
                  props.onClose();
                }}
              >
                Exit
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default DetailsModal;
